<!-- App Main Structure -->
<template>
  <div id="wrapper">
    <!-- Header of Admin Panel -->
    <app-admin-header />
    <div class="clearfix" />
    <div id="dashboard">
      <!-- Sidebar of Admin Panel -->
      <app-admin-sidebar />
      <!--Main Container -->
      <router-view />
    </div>
  </div>
</template>

<script>
// import AdminHeader from "Components/AdminHeader/AdminHeader.vue";
import AdminHeader from "Components/Header/Header.vue";
import AdminSidebar from "Components/AdminSidebar/AdminSidebar.vue";

export default {
	components: {
		appAdminHeader : AdminHeader,
		appAdminSidebar : AdminSidebar
	}
};
</script>
