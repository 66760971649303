import FrontendPanel from 'Layout/frontendPanel/FrontendPanel'

// dashboard components
const DashboardOne = () => import('Views/dashboard/DashboardOne');
const HappyHour = () => import('Views/HappyHour');
const LiveMusic = () => import('Views/LiveMusic');
const DealsEvents = () => import('Views/DealsEvents');
const AboutUs = () => import('Views/AboutUs');
const Advertise = () => import('Views/Advertise');
const Terms = () => import('Views/Terms');
const Privacy = () => import('Views/Privacy');
const Abuse = () => import('Views/Abuse');
const PlaceDetail = () => import('Views/PlaceDetail');
const LmPlaceDetail = () => import('Views/LmPlaceDetail');
const Contact = () => import('Views/ContactUs');
const Login = () => import('Views/Login');
const SignUp = () => import('Views/SignUp');
const ForgotPassword = () => import('Views/ForgotPassword');

export default {
   path: '/',
   component: FrontendPanel,
   redirect: '/',
   children: [
      // {
      //    path: '/',
      //    name:'home',
      //    component: DashboardOne,
      //    meta: {
      //       title: 'Home'
      //    }
      // },

      {
         path: '/',
         name:'Live Music',
         component: LiveMusic,
         meta: {
            title: 'Live Music'
         }
      },
      
      {
         path: '/login',
         component: Login,
         meta: {
            title: 'Login'
         }
      },
      {
			path: '/sign-up',
			component: SignUp,
			meta: {
				title: 'Signup'
			
			}
		},
      {
         path: '/about-us',
         component: AboutUs,
         meta: {
            title: 'AboutUs'
         }
      },
      {
         path: '/advertise',
         component: Advertise,
         meta: {
            title: 'Advertise'
         }
      },
      {
         path: '/terms',
         component: Terms,
         meta: {
            title: 'Terms'
         }
      },
      {
         path: '/abuse',
         component: Abuse,
         meta: {
            title: 'Abuse'
         }
      },
      {
         path: '/privacy',
         component: Privacy,
         meta: {
            title: 'Privacy'
         }
      },
      {
         path: '/contact-us',
         component: Contact,
         meta: {
            title: 'Contact'
         }
      },
      // {
      //    path: '/happy-hour',
      //    component: HappyHour,
      //    meta: {
      //       title: 'HappyHour'
      //    }
      // },
      {
         path: '/happy-hour/:slug',
         component: PlaceDetail,
         meta: {
            title: 'PlaceDetail'
         }
      },
      {
         path: '/live-music/:slug',
         component:LmPlaceDetail,
         meta: {
            title: 'LmPlaceDetail'
         }
      },
      {
         path: '/live-music',
         component: LiveMusic,
         meta: {
            title: 'LiveMusic'
         }
      },
      {
         path: '/forgot-password',
         component: ForgotPassword,
         meta: {
            title: 'ForgotPassword'
         }
      },
      // {
      //    path: '/deals-events/:slug',
      //    component:LmPlaceDetail,
      //    meta: {
      //       title: 'LmPlaceDetail'
      //    }
      // },
      {
         path: '/deals-events',
         component: DealsEvents,
         meta: {
            title: 'DealsEvents'
         }
      },
   ]
}
