import AdminendPanel from 'Layout/adminPanel/AdminPanel'

// dashboard components
const Dashboard = () => import('Views/adminPages/Dashboard');
const Messages = () => import('Views/adminPages/Messages');
const Bookings = () => import('Views/adminPages/Bookings');
const Reviews = () => import('Views/adminPages/Reviews');
const Bookmarks = () => import('Views/adminPages/Bookmarks');
const List = () => import('Views/adminPages/List');
const AddList = () => import('Views/adminPages/AddList');
const Profile = () => import('Views/adminPages/Profile');
const SelectAd = () => import('Views/Ads/SelectAd');

const PurchaseRealtorAd = () => import('Views/Ads/Realtor/PurchaseRealtorAd');
const CreateRealtorAd = () => import('Views/Ads/Realtor/CreateRealtorAd');
const RealtorCheckout = () => import('Views/Ads/Realtor/RealtorCheckout');

const PurchaseSponsorAd = () => import('Views/Ads/Sponsor/PurchaseSponsorAd');
const CreateSponsorAd = () => import('Views/Ads/Sponsor/CreateSponsorAd');
const SponsorCheckout = () => import('Views/Ads/Sponsor/SponsorCheckout');


const LocationPurchaseRealtorAd = () => import('Views/Ads/ChildLocation/Realtor/PurchaseRealtorAd');
const LocationCreateRealtorAd = () => import('Views/Ads/ChildLocation/Realtor/CreateRealtorAd');
const LocationRealtorCheckout = () => import('Views/Ads/ChildLocation/Realtor/RealtorCheckout');


const LocationPurchaseSponsorAd = () => import('Views/Ads/ChildLocation/Sponsor/PurchaseSponsorAd');
const LocationCreateSponsorAd = () => import('Views/Ads/ChildLocation/Sponsor/CreateSponsorAd');
const LocationSponsorCheckout = () => import('Views/Ads/ChildLocation/Sponsor/SponsorCheckout');
 

const PurchaseChildAd = () => import('Views/Ads/Child/PurchaseChildAd');
const CreateChildAd = () => import('Views/Ads/Child/CreateChildAd');
const ChildCheckout = () => import('Views/Ads/Child/ChildCheckout');


const AddLocation = () => import('Views/Ads/ChildLocation/AddLocation');
const SingleLocation = () => import('Views/Ads/ChildLocation/SingleLocation');


export default {
   path: '/admin',
   component: AdminendPanel,
   redirect: '/admin/dashboard',
   children: [
      // {
      //    path: '/dashboard',
      //    component: Dashboard,
      //    meta: {
      //       title: 'Dashboard'
      //    }
      // },
      // {
      //    path: '/admin/messages',
      //    component: Messages,
      //    meta: {
      //       title: 'Messages'
      //    }
      // },
      // {
      //    path: '/admin/bookings',
      //    component: Bookings,
      //    meta: {
      //       title: 'Bookings'
      //    }
      // },
      // {
      //    path: '/admin/reviews',
      //    component: Reviews,
      //    meta: {
      //       title: 'Reviews'
      //    }
      // },
      // {
      //    path: '/admin/bookmarks',
      //    component: Bookmarks,
      //    meta: {
      //       title: 'Bookmarks'
      //    }
      // },
      // {
      //    path: '/admin/list',
      //    component: List,
      //    meta: {
      //       title: 'List'
      //    }
      // },
      {
         path: '/admin/add-list',
         component: AddList,
         meta: {
            title: 'Add List'
         }
      },
      {
         path: '/profile',
         component: Profile,
         meta: {
            title: 'Profile'
         }
      },
      {
         path: '/select-ad',
         component: SelectAd,
         meta: {
            title: 'Select Ad'
         }
      },
      {
         path: '/purchase-realtor-ad',
         component: PurchaseRealtorAd,
         meta: {
            title: 'Purchase Realtor Ad'
         }
      },
      {
         path: '/create-realtor-ad',
         component: CreateRealtorAd,
         meta: {
            title: 'Create Realtor Ad'
         }
      },
      {
         path: '/realtor-checkout',
         component: RealtorCheckout,
         meta: {
            title: 'Realtor Checkout'
         }
      },


      //sponsor ads
      {
         path: '/purchase-sponsor-ad',
         component: PurchaseSponsorAd,
         meta: {
            title: 'Purchase Sponsor Ad'
         }
      },
      {
         path: '/create-sponsor-ad',
         component: CreateSponsorAd,
         meta: {
            title: 'Create Sponsor Ad'
         }
      },
      {
         path: '/sponsor-checkout',
         component: SponsorCheckout,
         meta: {
            title: 'Sponsor Checkout'
         }
      },

      //child Location 
          //sponsor ads
          {
            path: '/purchase-child-ad',
            component: PurchaseChildAd,
            meta: {
               title: 'Purchase child Ad'
            }
         },
         {
            path: '/create-child-ad',
            component: CreateChildAd,
            meta: {
               title: 'Create child Ad'
            }
         },
         {
            path: '/child-checkout',
            component: ChildCheckout,
            meta: {
               title: 'Child Checkout'
            }
         },

         // Added Locations

         {
            path: '/add-location',
            component: AddLocation,
            meta: {
               title: 'Add Location'
            }
         },
         {
            path: '/location/:slug',
            component: SingleLocation,
            meta: {
               title: 'Single Location'
            }
         },

         //Added Location Sponsor Ad

         {
            path: '/location/purchase-sponsor-ad',
            component: LocationPurchaseSponsorAd,
            meta: {
               title: 'Location Purchase SponsorAd'
            }
         },
         {
            path: '/location/create-sponsor-ad',
            component: LocationCreateSponsorAd,
            meta: {
               title: 'Location Create SponsorAd'
            }
         },
         {
            path: '/location/sponsor-checkout',
            component: LocationSponsorCheckout,
            meta: {
               title: 'Location Sponsor Checkout'
            }
         },

      //  added location realtor ad 

      {
         path: '/location/purchase-realtor-ad',
         component: LocationPurchaseRealtorAd,
         meta: {
            title: 'Location Purchase RealtorAd'
         }
      },
      {
         path: '/location/create-realtor-ad',
         component: LocationCreateRealtorAd,
         meta: {
            title: 'Location Create RealtorAd'
         }
      },
      {
         path: '/location/realtor-checkout',
         component: LocationRealtorCheckout,
         meta: {
            title: 'Location Realtor Checkout'
         }
      },
      
      



      
   ]
}
