<!-- Header Structure -->
<template>
  <div class="app-admin-sidebar"> 
    <div class="db-admin">
      <div class="dashboard-nav">
        <div class="dashboard-nav-inner">
          <!-- <ul data-submenu-title="Main">
            <li class="active">
              <router-link to="/admin/dashboard">
                <i class="fa fa-tachometer" /> Dashboard
              </router-link>
            </li>
            <li>
              <router-link to="/admin/messages">
                <i class="fa fa-inbox" /> Messages <span class="nav-tag messages">2</span>
              </router-link>
            </li>
            <li>
              <router-link to="/admin/bookings">
                <i class=" fa fa-calendar" /> Bookings
              </router-link>
            </li>
          </ul> -->
          <!-- <ul data-submenu-title="Listings">
            <li>
              <router-link to="/admin/list">
                <i class="fa fa-calendar-check-o" /> My Listing
              </router-link>
            </li>
            <li>
              <router-link to="/admin/reviews">
                <i class="fa fa-star" /> Reviews
              </router-link>
            </li>
            <li>
              <router-link to="/admin/bookmarks">
                <i class="fa fa-bookmark" /> Bookmarks
              </router-link>
            </li>
            <li>
              <router-link to="/admin/add-list">
                <i class="fa fa-calendar-plus-o" /> Add Listing
              </router-link>
            </li>
          </ul> -->
          <ul data-submenu-title="Account">
            <li>
              <router-link to="/profile">
                <i class="fa fa-user" /> My Profile
              </router-link>
            </li>
            <li v-if="role == 'sponsor'">
              <router-link to="/select-ad">
                <i class="fa fa-shopping-cart" aria-hidden="true"></i> Purchase Ad
              </router-link>
            </li>
            <li v-if="role == 'sponsor'">
              <router-link to="/advertise">
                <i class="fa fa-info-circle" aria-hidden="true"></i>About Ad
              </router-link>
            </li>
             <li>
              <a to="#" @click="logout" style="color: #5e72e4">
                <i class="fa fa-sign-out" /> Logout
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <a
      class="db-trigger"
      @click="toggleMenu()"
    >
      <i class="fa fa-reorder" />
    </a>
  </div>
</template>

<script>
export default {
  data(){
    return {
      role: localStorage.getItem('role')
    }
  },
	methods: {
		toggleMenu()
		{
			if ( $('#wrapper').hasClass( "sidebar-in" ) ) {
				$('#wrapper').removeClass("sidebar-in");
			}
			else
			{
				$('#wrapper').addClass("sidebar-in");
			}
		},
    logout() {
			localStorage.removeItem("token");
			 window.location.href = "/";
    },
	}
};
</script>
