
<template>
  <nav
    id="navigation"
    class="style-1"
  >
    <ul id="responsive">
      <li
        v-for="menuitem in menuItems"
        :key="menuitem.name"
        :class="{'open-menu': selectedMenu == menuitem.name}"
      >
        <router-link
          v-if="menuitem.type === 'link'"
          class="current"
          :to="menuitem.state"
        >
          {{ menuitem.name }}
        </router-link>
        <a
          v-if="menuitem.type === 'sub'"
          class="current"
          @click="menuClick(menuitem.name)"
        >{{ menuitem.name }}</a>
      </li>
    </ul>
  </nav>
</template>

<script>
import { menus } from './menu-items.js';
export default {
	data(){
		return{
			menuItems : menus,
			selectedMenu : null,
			selectedSubMenu : null
		}
	},
	methods: {
		
		menuClick(value)
		{
			if(this.selectedMenu == value)
			{
				this.selectedMenu = null;
			}
			else
			{
				this.selectedMenu = value;
			} 
		},

		subMenuClick(value)
		{
			if(this.selectedSubMenu == value)
			{
				this.selectedSubMenu = null;
			}
			else
			{
				this.selectedSubMenu = value;
			}
		}
	}
};
</script>
<style scoped>
.router-link-exact-active{
  border-bottom: 2px solid #e4486d;
}

@media (max-width: 992px){
#responsive > li:after {
content: " ";
}
}
</style>
