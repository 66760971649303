import Vue from 'vue'
import * as VueGoogleMaps from 'vue2-google-maps'
import App from './App.vue'

import 'bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';


import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";

import VueSweetalert2 from 'vue-sweetalert2';

// If you don't need the styles, do not connect
import 'sweetalert2/dist/sweetalert2.min.css';

import loader from "vue-ui-preloader";

import axios from 'axios';
import VueAxios from 'vue-axios'
import VueSocialauth from 'vue-social-auth'


var VueCookie = require('vue-cookie');
Vue.use(VueCookie);

Vue.use(VueAxios, axios)
Vue.use(VueSocialauth, {

  providers: {
    facebook: {
      clientId: '248865543947647',
     
    }
  }
});


Vue.use(loader);
Vue.use(VueSweetalert2);


// router
import router from './router'

// include script file
import './lib/CustomScript'

// include all css files
import './lib/CustomCss'

window.$ = require('jquery')
window.JQuery = require('jquery')

Vue.use(Toast);

Vue.use(VueGoogleMaps, {
   load: {
      key: "AIzaSyCJUOvQbh9bWCd_jX5tpCXq4x3wdPWoK8A",
      libraries: "places"
   }
})

Vue.config.productionTip = false

new Vue({
   router,
   components: { App },
   render: h => h(App)
}).$mount('#app')
