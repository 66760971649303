<template>
  <router-view />
</template>

<script>
import Vue from "vue";
export default {
    created() {
      if (localStorage.getItem("token")) {
        Vue.prototype.$isAuth = true;
      } else {
        Vue.prototype.$isAuth = false;
      }
  },
}
</script>
