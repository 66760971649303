import Vue from 'vue'
import Router from 'vue-router'

//routes
import frontendRoutes from './frontend';
import adminendRoutes from './admin';

// session components
// const SignUp = () => import('Views/session/SignUp');
// const Login = () => import('Views/session/Login');
const ForgotPassword = () => import('Views/session/ForgotPassword');
const ComingSoon = () => import('Views/session/ComingSoon');

Vue.use(Router)


const routerObj  =  new Router({
	scrollBehavior() {
		return { x: 0, y: 0 };
	},
	mode: 'history',
	routes: [
		frontendRoutes,
		adminendRoutes,
		
		
		{
			path: '/forgot-password',
			component: ForgotPassword,
			meta: {
				title: 'Forgot Password'
				// breadcrumb: 'Session / Forgot Password'
			}
		},
		{
			path: '/coming-soon',
			component: ComingSoon,
			meta: {
				title: 'Coming Soon'
				// breadcrumb: 'Session / Reset Password'
			}
		},

		
	]
});

ga('set', 'page', routerObj.currentRoute.path);
ga('send', 'pageview');

routerObj.afterEach(( to, from ) => {
  ga('set', 'page', to.path);
  ga('send', 'pageview');
});
export default routerObj;