<!-- Header Structure -->
<template>
  <header class="header-global">
    <nav
      id="navbar-main"
      class="navbar navbar-main fixed-top navbar-expand-lg navbar-transparent navbar-light headroom"
    >
      <div class="container">
        <div id="logo">
          <router-link to="/">
            <img
              src="/static/images/thriggle.png"
              width="150"
              alt=""
            >
          </router-link>
        </div>
        <button
         id="closeButtonMobile"
          class="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbar_global"
          aria-controls="navbar_global"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span class="navbar-toggler-icon" />
        </button>
        <div
          id="navbar_global"
          class="navbar-collapse collapse"
        >
          <div class="navbar-collapse-header">
            <div class="row">
              <div class="col-6 collapse-brand">
                <div id="logo">
                  <router-link to="/">
                    <img
                      class="img-fluid"
                      width="150"
                      src="/static/images/thriggle.png"
                      height="25"
                      alt=""
                    >
                  </router-link>
                </div>
              </div>
              <div class="col-6 collapse-close">
                <button
                
                  type="button"
                  class="navbar-toggler"
                  data-toggle="collapse"
                  data-target="#navbar_global"
                  aria-controls="navbar_global"
                  aria-expanded="false"
                  aria-label="Toggle navigation"
                >
                  <span />
                  <span />
                </button>
              </div>
            </div>
          </div>
          <div class="navbar-nav navbar-nav-hover align-items-lg-center ml-auto">
            <ul class="m-0 p-0">
              <app-menu />
            </ul>
             <ul class="navbar-nav" style="padding:0px !important;" >
            <li class="nav-item dropdown" v-if="$isAuth"   > 
              <a
                id="navbar-primary_dropdown_1"
                class="nav-link dropdown-toggle"
                href="#"
                role="button"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              ><img
                :src="imagePreview()"
                class="img-fluid rounded-circle shadow-lg mr-1"
                style="height:50px;width:50px;border-radius:50%;object-fit: cover;"
                alt=""
              >{{name}}</a>
              <div
                class="dropdown-menu dropdown-menu-right"
                aria-labelledby="navbar-primary_dropdown_1"
              >
                <router-link
                  class="dropdown-item"
                  to="/profile"
                >Profile</router-link>
                <a
                @click="logout"
                  class="dropdown-item"
                  href="#"
                >Logout</a>
              </div>
            </li>
             <li v-if="!$isAuth">
              <router-link
                class="current mr-3"
                to="/login"
                style="color:#ffffff"
              >
                LOGIN/SIGN UP
              </router-link>
            </li>
          </ul>
            <!-- <div class="header-widget">
              <router-link
                to="/advertise"
                class="btn btn-sm btn-neutral btn-icon btn-radius"
              >
                Advertise
              </router-link>
            </div> -->
          </div>
        </div>
      </div>
    </nav>
  </header>
</template>

<script>
import Menu from "Components/Menu/Menu.vue";
import axios from 'axios';
export default {
   components: {
      appMenu: Menu
   },
   data(){
     return {
       name: localStorage.getItem('name'),
     }
   },
   methods:{
    async logout() {
        // remove facebook session if exist
        // var fbSession =JSON.parse( sessionStorage.getItem('fbssls_248865543947647'));
        // console.log('FB SESSION IS:',fbSession)
        // if(typeof fbSession !== 'undefined'){
        //   if(fbSession.authResponse.accessToken){
        //     await axios
        //     .get(`https://www.facebook.com/x/oauth/logout?access_token=${fbSession.authResponse.accessToken}`)
        //     .then(response => {
        //       console.log('Logout Success');
        //       localStorage.removeItem('fblst_248865543947647');
        //       sessionStorage.removeItem('fbssls_248865543947647');
        //     })
        //     .catch(error =>  {
        //     });
        //   }
        // }
        // console.log('Cookie are:',this.cookie.get('fr'));
    
			localStorage.removeItem("token");
			localStorage.removeItem("user_id");
			localStorage.removeItem("role");
			localStorage.removeItem("image");
			localStorage.removeItem("name");
      window.location.href = "/";
    	},
      imagePreview() {
        let image = localStorage.getItem('image');
        let role = localStorage.getItem('role');
      if (image && image != "null") {
        if (image.indexOf("https://") > -1) {
          return image;
        } else {
           if(role == 'sponsor'){
            return process.env.VUE_APP_BASE_URL + "ads/" + image;
          }else{
            return process.env.VUE_APP_BASE_URL + role+ "s/" + image;
          }
        }
      } else {
        return "/static/images/man.png";
      }
    }
   }
};
</script>
<style scoped>
.navbar-nav .nav-link {
  padding:0px !important;
}

</style>
